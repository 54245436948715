import "./styles.scss";

import React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import classnames from "classnames";

import Button from "components/Button";

import scrollToElement from "utils/scrollToElement";
import priceFormat from "utils/priceFormat";

import ArrowIcon from "icons/arrow.svg";

const mainClass = "apartment-content";

const CustomArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button className={className}>
      <ArrowIcon onClick={onClick} />
    </button>
  );
};

const Content = ({ data, all_apartments }) => {
  const apartment_data = data?.data?.acfApartments;

  all_apartments.sort(function (a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  const breadcrumps_data = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: `${apartment_data?.type}  nr ${apartment_data?.number}`,
    },
  ];

  const slider_settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    arrows: true,
    draggable: true,
    useTransform: true,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 860,
        settings: {
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 530,
        settings: {
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__breadcrumps`}>
          {breadcrumps_data?.map(({ label, href }, index) =>
            !!href ? (
              <Link
                to={href}
                className={`${mainClass}__breadcrumps__item`}
                key={index}
              >
                {label}
              </Link>
            ) : (
              <span className={`${mainClass}__breadcrumps__item`} key={index}>
                {label}
              </span>
            )
          )}
        </div>
        <div className={`${mainClass}__wrapper`}>
          <div className={`${mainClass}__wrapper__left`}>
            <div className={`${mainClass}__wrapper__left__header`}>
              <h1>{`${apartment_data?.type} nr ${apartment_data?.number}`}</h1>
              <div className={`${mainClass}__wrapper__left__header__prices`}>
                <span>
                  {!!apartment_data?.extraPriceText
                    ? apartment_data?.extraPriceText
                    : "Inwestycja do 75% wartości mieszkania"}
                </span>
                <h4>{priceFormat(apartment_data?.price)}</h4>
                <h4>
                  {apartment_data?.area} m<sup>2</sup>
                </h4>
              </div>
            </div>
            <Slider
              className={`${mainClass}__wrapper__left__slider`}
              {...slider_settings}
            >
              {apartment_data?.gallery?.map(({ localFile }, index) => (
                <div key={index}>
                  <div
                    className={`${mainClass}__wrapper__left__slider__item`}
                    style={{
                      backgroundImage: `url(${localFile?.publicURL})`,
                    }}
                  />
                </div>
              ))}
            </Slider>
            <div className={`${mainClass}__wrapper__left__descriptions`}>
              {!!data?.data?.acfApartments?.description && (
                <div
                  id="opis-section"
                  className={`${mainClass}__wrapper__left__descriptions__item`}
                >
                  <h5>Szczegóły oferty</h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data?.data?.acfApartments?.description,
                    }}
                  />
                </div>
              )}
              {!!data?.data?.acfApartments?.commonParts && (
                <div
                  id="czesci-wspolne-section"
                  className={`${mainClass}__wrapper__left__descriptions__item`}
                >
                  <h5>Części wspólne</h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data?.data?.acfApartments?.commonParts,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={`${mainClass}__wrapper__right`}>
            <div className={`${mainClass}__wrapper__right__info`}>
              <div className={`${mainClass}__wrapper__right__header`}>
                <h4>{apartment_data?.floor} pietro</h4>
                <h4>
                  {apartment_data?.rooms}{" "}
                  {apartment_data?.rooms === 1
                    ? "pokój"
                    : apartment_data?.rooms > 4
                    ? "pokoi"
                    : "pokoje"}
                </h4>
              </div>
              <div className={`${mainClass}__wrapper__right__list`}>
                {apartment_data?.dataItems?.map(({ label, value }, index) => (
                  <div
                    key={index}
                    className={`${mainClass}__wrapper__right__list__item`}
                  >
                    <span>{label}</span>
                    <strong
                      dangerouslySetInnerHTML={{
                        __html: value,
                      }}
                    />
                  </div>
                ))}
              </div>
              <div className={`${mainClass}__wrapper__right__buttons`}>
                <Button onClick={() => scrollToElement(`#kontakt-section`)}>
                  zapytaj o mieszkanie
                </Button>
                {!!data?.data?.acfApartments?.description && (
                  <Button
                    variant="transparent"
                    onClick={() => scrollToElement(`#opis-section`, 140)}
                  >
                    szczegóły oferty
                  </Button>
                )}
                {!!data?.data?.acfApartments?.commonParts && (
                  <Button
                    variant="transparent"
                    onClick={() => scrollToElement(`#czesci-wspolne-section`)}
                  >
                    częsci wspólne
                  </Button>
                )}
              </div>
            </div>
            {!!apartment_data?.plan?.localFile?.publicURL && (
              <img src={apartment_data?.plan?.localFile?.publicURL} alt="" />
            )}
            <div className={`${mainClass}__more`}>
              <h4>Zmień mieszkanie</h4>
              <div className={`${mainClass}__more__items`}>
                {all_apartments?.map(({ id, label, href }, index) => (
                  <Link
                    key={index}
                    to={href}
                    className={classnames({
                      active: id === data?.data?.id,
                    })}
                  >
                    {label}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
