import React from "react";

import Layout from "components/Layout";
import Seo from "components/Layout/components/Seo";
import Content from "page_components/apartment/Content";

const Apartment = ({ location, pageContext }) => {
  const { pathname } = location;

  const all_apartments = pageContext?.all_apartments?.map(
    ({ id, uri, acfApartments }) => ({
      id,
      label: acfApartments?.number,
      href: uri,
    })
  );

  return (
    <Layout
      static_header={pathname === "/"}
      contact_data={pageContext?.contact_data}
    >
      <Content data={pageContext} all_apartments={all_apartments} />
    </Layout>
  );
};

export const Head = ({ pageContext }) => (
  <Seo
    title={`${pageContext?.data?.acfApartments?.type} nr ${pageContext?.data?.acfApartments?.number} | Wólczańska 4`}
  />
);

export default Apartment;
